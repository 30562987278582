import React from "react";
import Artykul from "../components/artykul";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import obrazekArtykulu from "../images/system-crm-w-zarzadzaniu-vlog.png";
import Lightbox from "../components/lightBox";
import ResponsiveEmbed from "react-responsive-embed";
import { Link } from "gatsby";
import * as styles from "./index.module.css";



export const query = graphql`
  {
    zdjecie1: file(relativePath: { eq: "wdrozenie-kultury-crm-wizja.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    zdjecie2: file(relativePath: { eq: "kpi-naplyw-nowych-szans.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;

const Specrm = ({ data }) => {
  return (
    <Artykul
      title="Wdrażanie kultury pracy z CRM w organizacji"
      keywords={["Wdrażanie kultury pracy z CRM w organizacji"]}
      articleImage={obrazekArtykulu}
      articleImageAlt="Wdrażanie kultury pracy z CRM w organizacji"
      metaTitle="Wdrażanie kultury pracy z CRM w organizacji"
      metaDescription="Wdrażanie kultury pracy z CRM w organizacji - czyli artykuł o tym, jak odnieść
      sukces w procesie wdrożenia systemu CRM"
    >
      <br />
      <br />
      <p>
        Systemy wspomagające zarządzanie relacjami z klientami niosą w sobie
        ogromny potencjał - zwłaszcza w obecnych czasach, w których model pracy
        z zespołem rozproszonym jest coraz bardziej popularny (a nierzadko -
        wręcz konieczny). Jednak bardzo wiele wdrożeń kultury pracy CRM kończy
        się zaledwie na instalacji systemu informatycznego. Takie podejście nie
        daje biznesowi zbyt wiele, a często zgoła nic.
      </p>
      <ResponsiveEmbed
        src="https://www.youtube.com/embed/uUOkTv49Lqg"
        allowFullScreen
        ratio="16:9"
      />
      <p>
        Materiał ten powstał po to, aby ułatwić managerom wdrożenie skutecznej
        organizacji opartej o kulturę pracy z CRM. Aby tego dokonać, należy
        najpierw podzielić cały ten proces na cztery etapy: wizja, uruchomienie
        systemu, wdrożenie zmiany oraz doskonalenie.
      </p>
      <br />
      <h2>Wizja, cele i procesy</h2>
      <p>
        Posiadanie wyraźnej wizji celu wdrożenia systemu CRM jest niezbędne do
        jego osiągnięcia i uniknięcia marnotrawstwa. Jeszcze przed rozpoczęciem
        poszukiwania odpowiedniego dla organizacji rozwiązania trzeba mieć jasno
        określone to, jakie problemy powinno rozwiązać nowe oprogramowanie.
      </p>

      <p>
        Następnie należy przejść od ustanowienia sobie długoterminowego celu.
        Może nim być zwiększenie skali, ekspansja zagraniczna, wchodzenie w nowe
        kanały dystrybucji, nowe segmenty klienckie i tak dalej. To ważne,
        ponieważ bez tego nie będziemy w stanie określić celów pośrednich, które
        to mają nas doprowadzić do sukcesu.
      </p>

      <p>
        Kolejnym krokiem jest opisanie procesów, które zachodzą w organizacji i
        które mają być usprawnione oraz tych, które mają być wdrożone w
        przyszłości. Plan wdrożenia powinien zakładać wdrożenie listy usprawnień
        do tych procesów, a realizacja powinna obejmować dostarczenie tych
        rozwiązań.
      </p>
      <br />
      <Lightbox
        style={{
          maxWidth: 800,
          margin: "0 auto",
        }}
        images={[data.zdjecie1]}
        alts={["Wdrożenie kultury CRM - wizja"]}
      />
      <br />
      <h2>Uruchomienie aplikacji CRM</h2>
      <p>
        Obowiązek dostarczenia odpowiedniego do wcześniejszych ustaleń
        rozwiązania leży zawsze po stronie dostawcy, dlatego w tym podpunkcie
        niniejszego artykułu nie będziemy wiele pisać o technicznej stronie
        implementacji systemu. Wystarczy, że wskażemy dwa ogólne rodzaje
        systemów CRM, w które może zainwestować dana organizacja. Procesy ich
        dostarczania w znacznym stopniu się od siebie różnią.
      </p>

      <p>
        W przypadku prostych aplikacji SaaS CRM jest to po prostu rejestracja
        (instalacja jest automatyczna) oraz konfiguracja. Są to rozwiązania tak
        proste, że nie wymagają wdrożenia, a ich konfiguracja leży po stronie
        klienta. Systemy CRM tego rodzaju są tańsze, ale za to mniej elastyczne.
      </p>

      <p>
        Rozwiązania dedykowane (Enterprise CRM) wymagają już wdrożenia, a cały
        projekt musi być zaplanowany i zorganizowany. Z uwagi na długi czas
        realizacji, rekomendowaną metodyką jest wdrożenie etapowe, z jednoczesną
        korektą planu i dostosowaniem go do zmieniającej się rzeczywistości.
      </p>
      <section
      style={{
        backgroundColor: "rgb(244, 244, 244, 0.75)",
        color: "rgb(48, 48, 48)",
        marginTop: 30,
        marginBottom: 30
      }}
    >
      <div className="container container--text"
      >
        <h3 style={{
          paddingTop: 30,
          textAlign: "center",
        }}>Chcesz uniknąć porażki we wdrożeniu systemu CRM?</h3>
        <p style={{
          textAlign: "center",
        }}
          >Napisz do nas, aby zyskać dostęp do bezpłatnej 30-dniowej wersji systemu CRM

</p>

      <div
            style={{
              textAlign: "center",
              margin: "20px",
              padding: "10px",
            }}
          >
            <Link className={styles.startNow} to="/kontakt/">
              Chcę wypróbować i poznać kulurę pracy z CRM
            </Link>
          </div>
          </div>
    </section>
      <br />
      <h2>Wdrożenie zmiany w biznesie</h2>
      <p>
        Na tym etapie wiele przedsiębiorstw natrafia na największe problemy.
        Żeby proces wdrożenia systemu CRM się udał, nie można poprzestać na
        samej jego instalacji.
      </p>

      <p>
        Należy przekonać pracowników do nowej wizji oraz celu zmiany, gdyż
        zazwyczaj mają oni tendencję do negatywnej oceny. Bardzo często widzą
        system CRM jako narzędzie do nadmiernej kontroli lub zbędnego
        napiętrzania dodatkowej pracy. Należy im wskazać, na czym mogą zyskać
        dzięki systemowi. Warto utworzyć instrukcje oraz tutoriale, które jasno
        wskazują im to, czego oczekujemy i co na tym zyska przedsiębiorstwo oraz
        oni sami. Takie materiały będą ponadto przydatne podczas wdrażania
        nowych pracowników.
      </p>

      <p>
        Zarządzanie zmianą w organizacji to obowiązek leżący po stronie biznesu.
        Dostawca IT powinien go wspierać, ale nie jest w stanie wdrożyć zmiany
        za klienta.
      </p>
      <br />
      <h2>Obserwacja i ciągłe doskonalenie</h2>
      <p>
        Prawie nigdy wdrożone rozwiązanie nie wpasowuje się w organizację
        natychmiast - zazwyczaj potrzeba na to nieco czasu. Kłopoty mogą
        wystąpić w obszarze funkcjonalnym (kiedy jeszcze nie wszystkie procesy
        są dobrze dotarte) lub personalnym (kiedy występują problemy z
        przekonaniem pracowników do zmiany).
      </p>
      <br />
      <Lightbox
        style={{
          maxWidth: 800,
          margin: "0 auto",
        }}
        images={[data.zdjecie2]}
        alts={["Przykład KPI - Napływ nowych szans"]}
      />
      <br />
      <p>
        Kluczem do sukcesu jest stała obserwacja procesu wdrożenia oraz
        pierwszego okresu funkcjonowania systemu - w czym wydatnie mogą pomóc
        raporty. To tutaj powinna mieć miejsce identyfikacja faktycznych
        problemów i eliminacja ich zgodnie z priorytetem. Jeżeli okazuje się, że
        opracowany wcześniej kształt systemu nieco zgrzyta w użytkowaniu, nie
        należy obawiać się wprowadzania do niego zmian już na etapie wdrożenia.
      </p>

      <p>
        Trzeba też pamiętać, żę osiągnięta przez wdrożenie systemu CRM harmonia
        i przewaga nie będą wieczna. Biznes musi ciągle poszukiwać nowych metod
        usprawnienia, ponieważ świat nie stoi w miejscu.
      </p>

      <p>
        Obserwacja biznesu z perspektywy systemu CRM daje dużą przewagę. To od
        managera zależy czy spocznie na laurach na jakimkolwiek etapie.
      </p>
      <br />
      <h2>Na zakończenie</h2>
      <p>
        Podsumowując, główną przyczyną porażek we wdrożeniach systemów CRM jest
        nieudana implementacja w organizacji kultury pracy z tym rozwiązaniem.
        Wynika to najczęściej z:
      </p>
      <p>
        <ul>
          <li>
            braku wizji, celów pośrednich oraz zaadresowania konkretnych
            problemów,
          </li>
          <li>
            braku przywództwa, poczucia odpowiedzialności oraz zaangażowania
            managementu,
          </li>
          <li>braku konsekwencji egzekucji lub wsparcia pracowników,</li>
          <li>braku etapu refleksji oraz otwartości na kolejne wyzwania.</li>
        </ul>
      </p>
      <p>
        Jeśli odpowiednio przemyślimy i zaplanujemy wdrożenie oraz zadbamy o
        spójność wizji pośród pracowników, system CRM stanie się wiatrem w żagle
        naszego przedsiębiorstwa. W przeciwnym razie będzie niczym więcej, jak
        zbędnym balastem.
      </p>
      <section
      style={{
        backgroundColor: "rgb(244, 244, 244, 0.75)",
        color: "rgb(48, 48, 48)",
        marginTop: 30,
        marginBottom: 30
      }}
    >
      <div className="container container--text"
      >
        <h3 style={{
          paddingTop: 30,
          textAlign: "center",
        }}>Chcesz uniknąć porażki we wdrożeniu systemu CRM?</h3>
        <p style={{
          textAlign: "center",
        }}
          >Napisz do nas, aby zyskać dostęp do bezpłatnej 30-dniowej wersji systemu CRM

</p>

      <div
            style={{
              textAlign: "center",
              margin: "20px",
              padding: "10px",
            }}
          >
            <Link className={styles.startNow} to="/kontakt/">
              Chcę wypróbować i poznać kulurę pracy z CRM
            </Link>
          </div>
          </div>
    </section>
    </Artykul>
  );
};

export default Specrm;
